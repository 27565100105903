import React, { Component} from 'react'
import data from '../../lib/backend/data'
import { toast } from 'react-toastify';
import { Spinner, Container, Row, Col, Button, Card } from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar'
import Modal from 'react-bootstrap/Modal'
import SearchLocationInput from '../../lib/maps/searchLocationInput'
import { Link } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import { validarHorario } from '../../lib/helpers/helpers';
const maps_key = 'AIzaSyA2UVu71Lhw-lWjuTloT-VGIylp_63lgRY'

class Checkout extends Component {
    constructor(props){
        super(props)
        this.state = {
            id_establecimiento: localStorage.getItem('id_establecimiento') ? localStorage.getItem('id_establecimiento') : '',
            loadingMaster: true,
            establecimiento: false,
            modal_ubicacion: false,
            ubicaciones_usuario: localStorage.getItem('my_places') ? JSON.parse(localStorage.getItem('my_places')) : [],
            carta_seleccionada: false,
            modal_producto: false,
            producto_seleccionado: false,
            carrito: localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [],
            show_drawer: false,
            pedido: {
                canal: 'web',
                productos : localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : []
            },
            loadingPedido: false,
            modal_pedido_recibido: false,
            modal_cerrado: false,
            abierto: false,
            color: '#393939',
            costo_delivery: false,
            tipos_pago: [],
            tipos_envio: []
        }

        this.handleChangePedido = this.handleChangePedido.bind(this)
        this.handleChangeDireccion = this.handleChangeDireccion.bind(this)

    }

    componentDidMount(){
        const { id_establecimiento } = this.state
        this.obtenerInformacion(id_establecimiento)
    }

    handleChangeDireccion(e){
        const { name, value } = e.target
        const pos = e.target.getAttribute('pos')
        const { ubicaciones_usuario } = this.state
        ubicaciones_usuario[pos][name] = value
        localStorage.setItem('my_places', JSON.stringify(ubicaciones_usuario))
        return this.setState({ ubicaciones_usuario })
    }

    cotizar(lugar, id_establecimiento){
        const loading = 'loadingMaster'
        console.log(lugar)
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/api/tarifas/cotizar`,{
            method: 'POST',
            body: JSON.stringify( {
                datos: {
                    id_establecimiento,
                    destino: {
                        pais: 'chile',
                        comuna: lugar.comuna.toLowerCase(),
                        region: lugar.region.toLowerCase()
                    }
                }
            } ),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            console.log(res)
            if(!res){
                toast.warn('No encontramos tarifas para tu dirección')
                return this.setState({ [loading]: false, costo_delivery: false })
            }
            this.setState({ [loading]: false, costo_delivery: res.precio })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    obtenerInformacion(id){
        const { color, ubicaciones_usuario } = this.state
        const buscar_direccion_seleccionada = ubicaciones_usuario.findIndex(ub => ub.status === true)
        if(buscar_direccion_seleccionada > -1){
            this.cotizar(ubicaciones_usuario[buscar_direccion_seleccionada], id)
        }
        return fetch(`${data.urlapi}/api/establecimientos/details-complete?id=${id}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('No se pudo obtener el registro')
                return this.setState({ loadingMaster: false })
            }
            const abierto = validarHorario(res.establecimiento)
            return this.setState({ 
                establecimiento: res, 
                color: res.establecimiento.color ? res.establecimiento.color : color, 
                modal_cerrado: abierto === true ? false : true, 
                carta_seleccionada: res.menus.length === 1 ? res.menus[0] : false, 
                abierto,
                tipos_pago: res.tipos_pago,
                tipos_envio: res.tipos_envio,
                loadingMaster: false 
            })
        })
        .catch(error => {
            this.setState({ loadingMaster: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    handleChangePedido(e){
        const { name, value } = e.target
        const { pedido } = this.state
        pedido[name] = value
        return this.setState({ pedido })
    }

    cargandoScreen(){
        return <Container>  
        <Row className="justify-content-md-center">
            <Col md={4} className="centerv text-center">
            <Spinner animation='border' />
            </Col>
        </Row>        
    </Container>
    }

    carritoVacioScreen(){
        const { id_establecimiento } = this.state
        return <Container fluid>  
        <Row className="justify-content-md-center">
            <Col md={4} className="centerv text-center">
            <h3>Carrito vacío</h3>
            <p>Aún no tienes productos en tu carrito</p>
            <Link to={`/detalles-${id_establecimiento}`}><Button size="sm" variant="outline-primary">Volver</Button></Link>
            </Col>
        </Row>        
    </Container>
    }

    handleClose(){
        return this.setState({ modal_ubicacion: false, modal_producto: false, producto_seleccionado: false, modal_cerrado: false })
    }

    handleClosePedido(){
        localStorage.setItem('cart', JSON.stringify([]))
        return this.setState({ modal_pedido_recibido: false, carrito: [], pedido: { canal: 'web' } })
    }

    detalleModal(){
        const { modal_ubicacion, ubicaciones_usuario, loadingMaster, id_establecimiento } = this.state
        return <Modal show={modal_ubicacion} onHide={()=>this.handleClose()} centered >
        <Modal.Header closeButton>
          <Modal.Title>Mis direcciones</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className='text-center mb-3'><img src="images/home.png" alt="inicio" width={140} /></div>
        <p className='mb-0'>Ingresa tu ubicación para el despacho de tu compra</p>
        <SearchLocationInput
                maps_key={maps_key}
                onChange={(place) => {
                    if(!place) return false
                    console.log(place)
                    const lat = place.geometry.location.lat()
                    const lng = place.geometry.location.lng()
                    let region = ''
                    const buscar_region = place.address_components.findIndex(comp => {
                        return comp.types.includes('administrative_area_level_1')
                    })
                    if(buscar_region > -1) region = place.address_components[buscar_region].long_name
                    
                    let comuna = ''
                    const buscar_comuna = place.address_components.findIndex(comp => {
                        return comp.types.includes('administrative_area_level_3')
                    })
                    if(buscar_comuna > -1) comuna = place.address_components[buscar_comuna].long_name
                    
                    let pais = ''
                    const buscar_pais = place.address_components.findIndex(comp => {
                        return comp.types.includes('country')
                    })
                    if(buscar_pais > -1) pais = place.address_components[buscar_pais].long_name

                    const lugar = {
                        lat, 
                        lng,
                        direccion: place.formatted_address,
                        region,
                        comuna,
                        status: true,
                        pais
                    }
                    this.setLugar_seleccionado(lugar)
                    return this.cotizar(lugar, id_establecimiento)
                }} />
                <h5 className='mt-3'>Mis direcciones</h5>
                { loadingMaster ? <Spinner animation='border' /> : false }
                {
                    ubicaciones_usuario.map((ub,i) => {
                        return <Card className='mb-3' key={`card-${i}`}>
                            <div className='p-2'>
                            <p className='mb-0 hover' onClick={()=>this.seleccionarDireccion(i)}> <b>{ ub.status === true ? <i className="fas fa-check-square text-success"></i> : false } {ub.direccion}</b></p>
                            <input className='form-control' defaultValue={ub.observaciones} pos={i} placeholder='Casa · Depto · Observaciones de la dirección' name="observaciones" onChange={this.handleChangeDireccion} />
                            </div>
                        </Card>
                    })
                }
        </Modal.Body>
      </Modal>
    }

    agregarCarrito(producto){
        const { carrito } = this.state
        const cantidad = producto.cantidad ? producto.cantidad : 1
        producto.cantidad = cantidad
        producto.precio_final = this.calcularPrecioFinal(producto)
        carrito.push(producto)
        localStorage.setItem('cart', JSON.stringify(carrito))
        toast.success('Agregado exitosamente')
        return this.setState({ carrito, modal_producto: false
         })
    }

    seleccionarAcicional(i,iopcion){
        const { producto_seleccionado } = this.state
        producto_seleccionado.adicionales[i].opciones[iopcion].seleccionado = producto_seleccionado.adicionales[i].opciones[iopcion].seleccionado === true ? false : true
        return this.setState({ producto_seleccionado })
    }

    calcularPrecioFinal(producto){
        let precio_final = producto.precio ? producto.precio : 0
        let cantidad = producto.cantidad ? producto.cantidad : 1
        precio_final = precio_final * cantidad
        // VER OPCIONES
        if(producto.opciones){
            if(Array.isArray(producto.opciones)){
                if(producto.opciones.length > 0){
                    precio_final = 0
                    const encontrar_seleccion = producto.opciones.findIndex(op => op.seleccionado === true)
                    if(encontrar_seleccion > -1){
                        const precio_opcion_seleccionada = producto.opciones[encontrar_seleccion].precio
                        if(isNaN(precio_opcion_seleccionada) !== true){
                            precio_final = precio_opcion_seleccionada * cantidad
                        }
                    }
                }
            }
        }

        // VER ADICIONALES
        if(producto.adicionales){
            if(Array.isArray(producto.adicionales)){
                if(producto.adicionales.length > 0){
                    const encontrar_adicionales_seleccionados = producto.adicionales.filter(op => {
                        if(!op.opciones) return false
                        if(Array.isArray(op.opciones) !== true) return false
                        if(op.opciones.length < 1) return false
                        return op.opciones.filter(ad => ad.seleccionado === true).length > 0
                    })
                    for(const adicional of encontrar_adicionales_seleccionados){
                        for( const opcion of adicional.opciones ){
                            if(opcion.seleccionado !== true) continue
                            if(isNaN(opcion.precio) !== true){
                                precio_final = precio_final + opcion.precio      
                            }
                        }
                    }
                }
            }
        }

        return precio_final
    }

    mostrarAdicionales(adicionales){
        if(adicionales.length < 1) return false
        return <div>
            <h4>Adicionales</h4>
            {
                adicionales.map((opcion,i) => {
                    return <div key={`maxsub-${i}`}>
                        <h5>{opcion.titulo} <span style={{ fontSize: 12 }} className='text-warning'>Opcional</span></h5>
                        {
                            opcion.opciones.map((subopcion,isub) => {
                                return <div key={`opcion-${isub}`}>
                                    <Row>
                                        <Col xs={6} className="hover" onClick={()=>this.seleccionarAcicional(i,isub)}>{ subopcion.seleccionado === true ? <i className="text-success fas fa-check-square"></i> : <i className="far fa-square"></i> } {subopcion.titulo}</Col>
                                        <Col xs={6} className='text-right'> {parseInt(subopcion.precio) > 0 ? `$${Intl.NumberFormat("es-ES").format(subopcion.precio)}` : false } </Col>
                                    </Row>
                                </div>
                            })
                        }
                        {adicionales.length - 1 === i ? false : <hr />}
                    </div>
                })
            }
        </div>
    }

    seleccionarOpcion(i){
        const { producto_seleccionado } = this.state
        producto_seleccionado.opciones.map((op,iop) => producto_seleccionado.opciones[iop].seleccionado = false)
        producto_seleccionado.opciones[i].seleccionado = true
        console.log(producto_seleccionado)
        return this.setState({ producto_seleccionado })
    }

    mostrarOpciones(opciones){
        if(opciones.length < 1) return false
        return <div>
            <h4>Opciones <span style={{ fontSize: 12 }} className='text-danger'>Requerido</span></h4>
            {
                opciones.map((opcion,i) => {
                    return <div key={`opcion-${i}`}>
                        <Row>
                            <Col xs={6} className="hover" onClick={()=>this.seleccionarOpcion(i)} >{ opcion.seleccionado === true ? <i className="text-success fas fa-check-square"></i> : <i className="far fa-square"></i> } {opcion.titulo}</Col>
                            <Col xs={6} className='text-right'> {parseInt(opcion.precio) > 0 ? `$${Intl.NumberFormat("es-ES").format(opcion.precio)}` : false } </Col>
                        </Row>
                    </div>
                })
            }
        </div>
    }

    mostrarConfiguracion(configuracion){
        if(!configuracion) return false
        if(Array.isArray(configuracion) !== true) return false
        if(configuracion.length < 1) return false
        return <div>
            <hr />
            <h4>Selecciona las opciones </h4>
            {
                configuracion.map((opcion,i) => {
                    return <div key={`maxsub-${i}`}>
                        <h5>{opcion.titulo} <span style={{ fontSize: 12 }} className='text-danger'>Requerido</span></h5>
                                <div >
                                    <Row>
                                        <Col xs={12} className="hover">
                                             <select className='form-control' pos={i} onChange={this.handleChangeConfig} >
                                                 <option value="">Selecciona</option>
                                                 {
                                                     opcion.opciones.map((subopcion,isub) => {
                                                         return <option value={subopcion.titulo}>{subopcion.titulo}</option>
                                                     })
                                                 }
                                             </select>
                                        </Col>
                                    </Row>
                                </div>
                        {configuracion.length - 1 === i ? false : <hr />}
                    </div>
                })
            }
        </div>
    }

    detalleModalProducto(){
        const { modal_producto, producto_seleccionado } = this.state
        if(!producto_seleccionado) return false

        const opciones = producto_seleccionado.opciones ? producto_seleccionado.opciones : []
        const adicionales = producto_seleccionado.adicionales ? producto_seleccionado.adicionales : []
        const configuracion = producto_seleccionado.configuracion ? producto_seleccionado.configuracion : []

        return <Modal show={modal_producto} onHide={()=>this.handleClose()} centered size="xl" >
        <Row>
                <Col md={6}>
                <img style={{ maxHeight: '80vh', maxWidth: '100%', minWidth: '100%' }} alt="producto" src={producto_seleccionado.imagen} />
                </Col>

                <Col md={6}>
                    <div className='pt-3 pb-3 pr-4 box-producto'>
                        <h2>{producto_seleccionado.titulo}</h2>
                        <p>{producto_seleccionado.descripcion}</p>
                        <div className='bloque-opciones-producto'>
                            {this.mostrarOpciones(opciones)}
                            { opciones.length > 0 && adicionales.length > 0 ? <hr /> : false }
                            {this.mostrarAdicionales(adicionales)}
                            {this.mostrarConfiguracion(configuracion)}
                        </div>
                        <Row className='pr-4 pl-3 botonera-modal-producto'>
                            <Col xs={4} className='p-0 bg-white'>
                                <Button variant='white' onClick={()=>this.sumaRestaProducto('resta')}><i className="fas fa-minus"></i></Button>
                                <Button variant='white'>{producto_seleccionado.cantidad ? producto_seleccionado.cantidad : 1 }</Button>
                                <Button variant='white' onClick={()=>this.sumaRestaProducto('suma')}><i className="fas fa-plus"></i></Button>
                            </Col>
                            <Col xs={8}>
                            {this.permitirAgregarCarrito(producto_seleccionado)}
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
      </Modal>
    }

    sumaRestaProducto(operacion){
        const { producto_seleccionado } = this.state
        const cantidad_actual = producto_seleccionado.cantidad ? producto_seleccionado.cantidad : 1
        if(operacion === 'suma'){
            producto_seleccionado.cantidad = cantidad_actual + 1
        } else {
            if(cantidad_actual > 1){
                producto_seleccionado.cantidad = cantidad_actual - 1
            }
        }
        return this.setState({ producto_seleccionado })
    }

    permitirAgregarCarrito(producto){
        if(producto.opciones){
            if(Array.isArray(producto.opciones)){
                if(producto.opciones.length > 0){
                    const encontrar_seleccion = producto.opciones.findIndex(op => op.seleccionado === true)
                    if(encontrar_seleccion < 0) return <Button variant='secondary' style={{ width: '100%' }} >ELIGE UNA OPCIÓN</Button>
                }
            }
        }

        return <Button variant='primary' style={{ width: '100%' }} onClick={()=>this.agregarCarrito(producto)} >AGREGAR ${Intl.NumberFormat("es-ES").format(this.calcularPrecioFinal(producto))}</Button>
    }

    seleccionarDireccion(pos){
        const { ubicaciones_usuario, id_establecimiento } = this.state
        ubicaciones_usuario.map((ub,i) => ubicaciones_usuario[i].status = false)
        ubicaciones_usuario[pos].status = true
        this.cotizar(ubicaciones_usuario[pos], id_establecimiento)
        localStorage.setItem('my_places', JSON.stringify(ubicaciones_usuario))
        return this.setState({ ubicaciones_usuario })
    }

    setLugar_seleccionado(place){
        const { ubicaciones_usuario } = this.state
        const si_existe = ubicaciones_usuario.findIndex(ub => ub.direccion === place.direccion)
        ubicaciones_usuario.map((ub,i) => ubicaciones_usuario[i].status = false)
        if(si_existe > -1){
            ubicaciones_usuario[si_existe].status = true
            toast.warn('Esta dirección ya existe')
        } else {
            ubicaciones_usuario.push(place)
        }
        localStorage.setItem('my_places', JSON.stringify(ubicaciones_usuario))
        return this.setState({ ubicaciones_usuario })
    }

    ifInCarrito(id){
        const { carrito } = this.state
        const cuantos = carrito.filter(pro => pro._id === id).length
        if(cuantos > 0) return <Badge className='contador-carrito bg-danger text-light' >{cuantos}</Badge>
        return false
    }

    toggleDrawer(e){
        return this.setState({ show_drawer: false })
    }

    mostrarOpcionEscogida(producto){
        if(producto.opciones){
            if(Array.isArray(producto.opciones)){
                if(producto.opciones.length > 0){
                    const encontrar_seleccion = producto.opciones.findIndex(op => op.seleccionado === true)
                    if(encontrar_seleccion > -1){
                        return <p className='mb-0'>{producto.opciones[encontrar_seleccion].titulo}</p>
                    }
                }
            }
        }
        return false
    }

    visualizarAdicionales(producto){
        let escogidos = []
        if(producto.adicionales){
            if(Array.isArray(producto.adicionales)){
                if(producto.adicionales.length > 0){
                    const encontrar_adicionales_seleccionados = producto.adicionales.filter(op => {
                        if(!op.opciones) return false
                        if(Array.isArray(op.opciones) !== true) return false
                        if(op.opciones.length < 1) return false
                        return op.opciones.filter(ad => ad.seleccionado === true).length > 0
                    })
                    for(const adicional of encontrar_adicionales_seleccionados){
                        for( const opcion of adicional.opciones ){
                            if(opcion.seleccionado !== true) continue
                            escogidos.push(opcion.titulo)
                        }
                    }
                }
            }
        }
        return escogidos.length > 0 ? escogidos.map((item,i) => <span className='spanguia mr-2' key={`item-${i}`}>{item}</span>) : false
    }

    removerDeCarrito(i){
        const { carrito } = this.state
        carrito.splice(i,1)
        localStorage.setItem('cart', JSON.stringify(carrito))
        return this.setState({ carrito   })
    }

    visualizarConfiguracion(producto){
        let escogidos = []
        if(producto.configuracion){
            if(Array.isArray(producto.configuracion)){
                if(producto.configuracion.length > 0){
                    const encontrar_config_seleccionado = producto.configuracion.filter(op => {
                        if(!op.opciones) return false
                        if(Array.isArray(op.opciones) !== true) return false
                        if(op.opciones.length < 1) return false
                        return op.opciones.filter(ad => ad.seleccionado === true).length > 0
                    })
                    for(const config of encontrar_config_seleccionado){
                        for( const opcion of config.opciones ){
                            if(opcion.seleccionado !== true) continue
                            escogidos.push(opcion.titulo)
                        }
                    }
                }
            }
        }
        return escogidos.length > 0 ? escogidos.map((item,i) => <span className='spanguia mr-2' key={`item-${i}`}>{item}</span>) : false

    }

    mostrarProductos(carrito){
        if(carrito.length < 1) return <h3>Aún no tienes productos agregados</h3>

        let calcular_total = carrito.reduce((prev, next) => prev + this.calcularPrecioFinal(next), 0)

        return <div>
            <div className='box-producto-carrito'>
            <h5><i className="fas fa-arrow-left hover" onClick={()=>this.toggleDrawer()} ></i> Detalles de tu pedido</h5>
            <h5 className='text-danger mb-3'>{carrito.length} Productos</h5>
            {
                carrito.map((pro,i) => {
                    return <Row key={`pro-${i}`}>
                        <Col xs={8}>
                            <h5 className='mb-0'>{pro.titulo} <span className='text-danger'>X{pro.cantidad}</span> {this.visualizarConfiguracion(pro)}</h5>
                            {this.mostrarOpcionEscogida(pro)}
                            <h6 className='mb-0'>${Intl.NumberFormat("es-ES").format(pro.precio_final)}</h6>
                            {this.visualizarAdicionales(pro)}
                            <Button size="sm" variant="outline-danger" className="mt-2" style={{ fontSize:10 }} onClick={()=>this.removerDeCarrito(i)}>REMOVER</Button>
                        </Col>
                        <Col xs={4}>
                        <img alt="producto-imagen" src={pro.imagen} style={{ width: '100%' }} />
                        </Col>
                        {carrito.length - 1 === i ? false : <Col xs={12} ><hr /></Col> }
                    </Row>
                })
            }
            </div>
            <Row className='pr-4 pl-3 botonera-modal-producto'>
                            <Col xs={12}>
                            <Button variant='primary' style={{ width: '100%' }} onClick={()=>this.realizarPedido()}>REALIZAR PEDIDO ${Intl.NumberFormat("es-ES").format(calcular_total)}</Button>
                            </Col>
                        </Row>
        </div>
    }

    mostrarCarrito(){
        const { show_drawer, carrito } = this.state

        return <Drawer
        open={show_drawer}
        onClose={()=>this.toggleDrawer()}
        direction='right'
        size={400}
        className='drawer-carrito'
    >
        <div className='p-3' >
            {this.mostrarProductos(carrito)}
        </div>
    </Drawer>
    }

    modalPedidoExitoso(){
        const { modal_pedido_recibido } = this.state
        return <Modal show={modal_pedido_recibido} onHide={()=>this.handleClosePedido()} centered >
        <Modal.Header closeButton>
          <Modal.Title>¡Pedido recibido!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className='text-center mb-3'><img src="images/home.png" alt="inicio" width={140} /></div>
        <p className='mb-0'>Tu pedido fue recibido exitosamente, recibirás actualización en tu teléfono y/o email</p>
        </Modal.Body>
      </Modal>
    }

    boxesProducto(productos, titulo){
        
        return <Row>
            <Col xs={12}>
                <h3>{titulo}</h3>
                <hr className='hr' />
            </Col>
            {
                productos.map((pro,ip) => {
                    return <Col md={4} className='mb-3' key={`box-${ip}`}>
                        <Card className="hover" onClick={()=>this.setState({  producto_seleccionado: pro, modal_producto: true })}>
                        
                        <Row>
                            <Col xs={7}>
                            <div className='p-3'>
                                <h5>{pro.titulo} </h5>
                                
                                <p style={{ fontSize: 12 }}>{pro.descripcion}</p>
                                <h6>${Intl.NumberFormat("es-ES").format(pro.precio)}</h6>
                            </div>
                            </Col>
                            <Col xs={5}>
                            {this.ifInCarrito(pro._id)}
                                <img alt="producto-imagen" src={pro.imagen} style={{ width: '100%' }} />
                            </Col>
                        </Row>
                        
                    </Card>
                    </Col>
                })
            }
        </Row>
    }

    setMedioPago(tipo_pago){
        const { pedido } = this.state
        pedido.tipo_pago = tipo_pago
        return this.setState({ pedido })
    }

    ifTipoPagoChecked(tipopago, pedido){
        if(pedido.tipo_pago === tipopago) return <i className="text-success fas fa-check-square"></i>
        return <i className="far fa-square"></i>
    }

    setPropina(monto){
        const { pedido } = this.state
        pedido.propina = monto
        return this.setState({ pedido })
    }

    propina(){
        const { pedido } = this.state

        return <div className='mb-3'>
            <h6>Propina</h6>
            <span className={`${pedido.propina === 0 || typeof pedido.propina === 'undefined' ? 'opcion-propina-on' : 'opcion-propina-off'} hover`} onClick={()=>this.setPropina(0)} >$0</span>
            <span className={`${pedido.propina === 800 ? 'opcion-propina-on' : 'opcion-propina-off'} hover`} onClick={()=>this.setPropina(800)} >$800</span>
            <span className={`${pedido.propina === 1000 ? 'opcion-propina-on' : 'opcion-propina-off'} hover`} onClick={()=>this.setPropina(1000)} >$1.000</span>
        </div>
    }
    
    async confirmarPedido(){
        const { pedido, ubicaciones_usuario, carrito, costo_delivery, id_establecimiento, abierto } = this.state
        
        if(!abierto){
            return this.setState({ modal_cerrado: true })
        }
        
        if(!pedido.tipo_entrega) return toast.error('Falta método de envío')

        if(pedido.tipo_entrega === 'delivery'){
            if(costo_delivery === false){
                return toast.error('No contamos con despacho para tu dirección')
            }
        }

        pedido.creacion = new Date()
        pedido.id_establecimiento = id_establecimiento
        pedido.status = 0
        pedido.delivery = costo_delivery
        const buscar_direccion_seleccionada = ubicaciones_usuario.findIndex(ub => ub.status === true)
        if(buscar_direccion_seleccionada < 0) return toast.error('Selecciona una dirección')
        pedido.destino = ubicaciones_usuario[buscar_direccion_seleccionada]
        let subtotal = carrito.reduce((prev, next) => prev + this.calcularPrecioFinal(next), 0)

        let total = subtotal
        if(costo_delivery > 0){
            total = total + costo_delivery
        }

        const propina = pedido.propina ? pedido.propina : 0
        if(propina) total = total + propina
        pedido.total = total
        pedido.subtotal = subtotal
        
        if(!pedido.tipo_pago) return toast.error('Falta medio de pago')
        if(!pedido.nombre) return toast.error('Falta nombre')
        if(!pedido.email) return toast.error('Falta email')
        if(pedido.movil){
            if(pedido.movil.length < 8) return toast.error('Móvil inválido')
            pedido.movil = `+569${pedido.movil}`
        }
        if(!this.validateEmail(pedido.email)) return toast.error('Email inválido')
        
        await this.crearPedido(pedido)
        
    }

    crearPedido(pedido){
        this.setState({ loadingPedido: true })
        return fetch(`${data.urlapi}/api/pedidos/new`,{
            method:'POST',
            body: JSON.stringify(pedido),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingPedido: false })
            if( res.errorMessage ){
                toast.error(res.errorMessage)
                return false
            }
            if( res._id ){
                toast.success('Pedido creado exitosamente')
                this.setState({ modal_pedido_recibido: true })
                return true
            }
        })
        .catch(error => {
            this.setState({ loadingPedido: false })
            toast.error('No se pudo crear el pedido')
            return false
        })
    }

    validateEmail(email) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }

    navbar(){
        const { id_establecimiento, color } = this.state
        return <Navbar variant="dark" style={{ backgroundColor: color }}>
        <Container>
        <Navbar.Brand >
        <h3><Link to={`/detalles-${id_establecimiento}`} className='text-white' ><i className="fas fa-arrow-left hover" ></i></Link> Tu pedido</h3>
        </Navbar.Brand>
        </Container>
        </Navbar>
    }

    setTipoEntrega(string){
        const { pedido } = this.state
        pedido.tipo_entrega = string
        return this.setState({ pedido })
    }

    mostrarInterfaz(){
        const { pedido, loadingMaster, tipos_envio, loadingPedido, tipos_pago, costo_delivery, carrito, ubicaciones_usuario, id_establecimiento, establecimiento } = this.state
        if(loadingMaster) return this.cargandoScreen()
        if(!establecimiento) return false

        if( carrito.length < 1 || !id_establecimiento ) return this.carritoVacioScreen()
        
        let titulo = 'Ingresa tu ubicación'
        const buscar_direccion_seleccionada = ubicaciones_usuario.findIndex(ub => ub.status === true)

        if( buscar_direccion_seleccionada > -1 ){
            titulo = ubicaciones_usuario[buscar_direccion_seleccionada].direccion
            titulo = `${titulo} ${ubicaciones_usuario[buscar_direccion_seleccionada].observaciones}`
        }
        
        let calcular_total = carrito.reduce((prev, next) => prev + this.calcularPrecioFinal(next), 0)

        let total = calcular_total
        if(costo_delivery > 0){
            total = total + costo_delivery
        }

        const propina = pedido.propina ? pedido.propina : 0
        if(propina) total = total + propina

        return <div>
            {this.navbar()}

            <Container className='pt-3'>
            <Row>
                <Col md={7}>
                    <h4>Detalles de entrega</h4>
                    <Row>
                        <Col xs={7}>
                        <h6>Delivery</h6>
                        <p className='mb-0'>{titulo}</p>
                        <h6 className='hover text-primary' onClick={()=>this.setState({ modal_ubicacion: true })}>Editar</h6>
                        </Col>
                        <Col xs={5}>
                        <h6>Tiempo de entrega</h6>
                        <p className='mb-0'><i className="far fa-clock"></i> {establecimiento.establecimiento.tiempo_espera}</p>
                        </Col>
                    </Row>
                    <hr className='hr' />
                    <h4>Tipo de entrega</h4>

                    <Row>
                        {
                            tipos_envio.length > 0 ? tipos_envio.map(tipoenvio => {
                                return <Col xs={6} key={tipoenvio._id}>
                                <Card onClick={()=>this.setTipoEntrega(tipoenvio.slug)} >
                                    <div className="p-3 hover" >
                                        <h5 className={'text-center mb-0'}>
                                            { pedido.tipo_entrega === tipoenvio.slug ? <i className="fas fa-check-square text-success"></i> : <i className="far fa-square"></i> } {tipoenvio.title}
                                        </h5>
                                    </div>
                                </Card>
                            </Col>
                            }) : <Col xs={12}><h4>No hay métodos de envío habilidatos</h4></Col>
                        }
                    </Row>

                    <hr />

                    <h4>Detalles personales</h4>
                    <label className='form-control-label'>Nombre</label>
                    <input name="nombre" placeholder='Ingresa tu nombre y apelelido' className='form-control mb-2' onChange={this.handleChangePedido} />

                    <label className='form-control-label d-block'>Móvil <span className='text-secondary' style={{ fontSize: 11 }}>Opcional</span></label>
                    <p style={{ marginBottom: -30, paddingLeft: 10 }}><b>+569</b></p>
                    <input maxLength={8} style={{ paddingLeft: 55 }} name="movil" placeholder='Ingresa tu número de teléfono' className='form-control mb-2' onChange={this.handleChangePedido} />

                    <label className='form-control-label'>Email </label>
                    <input name="email" placeholder='Ingresa tu correo electrónico' className='form-control mb-3' onChange={this.handleChangePedido} />
                    
                </Col>

                <Col md={5}>
                    <Card className='shadow'>
                        <Card.Body>
                        <Row>
                            <Col xs={7}>
                            <h4><Badge className='bg-danger text-light' >{carrito.length}</Badge> Productos</h4>
                            </Col>
                            <Col xs={5} className='text-right'><h6 className='hover text-primary mb-0' onClick={()=>this.setState({ show_drawer: true })}>Ver Pedido</h6></Col>
                        </Row>
                        <hr className='hr' />
                        <Row>
                            <Col xs={8}>
                            <h6>Subtotal</h6>
                            </Col>
                            <Col xs={4} className='text-right'>
                            ${Intl.NumberFormat("es-ES").format(calcular_total)}   
                            </Col>
                            <Col xs={8}>
                            <h6>Delivery</h6>
                            </Col>
                            <Col xs={4} className='text-right'>
                            ${Intl.NumberFormat("es-ES").format(costo_delivery)}   
                            </Col>
                            <Col xs={12}>
                            <hr />
                            {this.propina()}
                            <h6>Forma de pago</h6>

                            {
                                tipos_pago.length > 0 ? tipos_pago.map((tipopago, i) => {
                                    return <Row key={tipopago._id} className='hover' onClick={() => this.setMedioPago(tipopago.slug)}>
                                    <Col xs={8} >{tipopago.title}</Col>
                                    <Col xs={4} className='text-right'>{this.ifTipoPagoChecked(tipopago.slug,pedido)}</Col>
                                </Row>
                                }) : <h5>No hay métodos de pago disponibles</h5>
                            }

                            </Col>
                        </Row>
                        <hr className='hr' />
                        <Row>
                            <Col xs={6}>
                            <h4>TOTAL <span className='text-success'>${Intl.NumberFormat("es-ES").format(total)}</span> </h4>
                            </Col>
                            <Col xs={6} className='text-right'>
                            { loadingPedido ? <Spinner animation='border' /> : <Button variant="primary" style={{ width: '100%' }} onClick={()=>this.confirmarPedido()}>CONFIRMAR PEDIDO</Button>}
                            </Col>
                            <Col xs={12} className='mt-3'>
                                <p style={{ fontSize: 11 }} className='mb-0'><b>Al confirmar tu pedido declaras haber leído y aceptado los <Link to="/">términos y condiciones de uso</Link></b></p>
                            </Col>
                        </Row>
                        </Card.Body>
                    </Card>
                </Col>

            </Row>
            </Container>
        </div>
    }

    modalCerrado(){
        const { modal_cerrado } = this.state
        return <Modal show={modal_cerrado} onHide={()=>this.handleClose()} centered >
        <Modal.Header closeButton>
          <Modal.Title>¡Cerrado!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p className='mb-0'>En este momento estamos cerrados</p>
        </Modal.Body>
      </Modal>
    }

    render(){
        return <div>
            {this.modalCerrado()}
            {this.modalPedidoExitoso()}
            {this.mostrarCarrito()}
            {this.detalleModal()}
            {this.mostrarInterfaz()}
        </div>
    }

}

export default Checkout