import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Login from './components/Login';
import store from './redux/store';
import reportWebVitals from './reportWebVitals'
import 'bootstrap/dist/css/bootstrap.min.css'
import { PrivateRoute } from './components/PrivateRoute';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Carta from './components/Carta';
import Checkout from './components/Checkout';
import Mesas from './components/Mesas';

require('dotenv').config()

const App = (<div>
  <ToastContainer
          position="bottom-center"
          toastStyle={{ backgroundColor: "black" }}
          hideProgressBar={true}
          />
  <Provider store={store}>
    
    <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Carta} />      
    </Switch>
  </BrowserRouter>
  </Provider>
  </div>) 

ReactDOM.render(App, document.getElementById('root'))
reportWebVitals();
