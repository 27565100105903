const { useState } = require("react")
const { Navbar, Container, Nav, Button } = require("react-bootstrap")
const { Link } = require("react-router-dom")

const Header = (props) => {
    const carrito = props.carrito ? props.carrito : []
    const logotipo = props.logotipo ? props.logotipo : ""

    const openCart = () => {
        if(props.openCart) props.openCart()
        return false
    }

    return <Navbar  variant="dark" style={{ backgroundColor: props.color }}>
    <Container>
    <Navbar.Brand href="#home">
    <img
        src={logotipo}
        height="35"
        className="d-inline-block align-top"
        alt="Logotipo"
    />
    </Navbar.Brand>
    <Nav className="me-auto">
    <Nav.Link><Link to="/mesas" className="nav-link text-white">Mesas</Link></Nav.Link>
    <Nav.Link><Link to="/" className="nav-link text-white">Menú</Link></Nav.Link>
    <Nav.Link  className='text-light'><Button variant="outline-light" size="sm" onClick={()=>openCart()} ><i className="fas fa-shopping-cart"></i> {carrito.length} Items</Button> </Nav.Link>
    <Nav.Link ><Link to="/login" className='text-danger'>SALIR</Link></Nav.Link>
    </Nav>
    </Container>
    </Navbar>
}

export default Header