const control = {}

const configuracion_fechas = {
    weekday: 'long', 
    year: 'numeric', 
    month: 'long', 
    day: 'numeric'
}

const configuracion_horas = {
    timeZone: 'America/Santiago',
    hour: '2-digit',
    minute: '2-digit'
}

control.fechaATexto = (fecha, lang) => {
    const date = new Date(fecha)
    return `${date.toLocaleDateString('es-ES', configuracion_fechas )} · ${date.toLocaleTimeString('en-US', configuracion_horas)}`
}

control.ordenarProductos = (registros) => {
  const nuevos = registros.sort((a,b)=> {
    const posicion = typeof a.orden !== 'undefined' ? a.orden : 99999999
    return posicion > b.orden ? 1 : -1
  })
  return nuevos
}

control.ordenarRegistros = (registros) => {
    const nuevos = registros.sort((a,b)=> {
      const posicion = typeof a.orden !== 'undefined' ? a.orden : 99999999
      return posicion > b.orden ? 1 : -1
    })
    return nuevos
  }

control.limpiarRut = (rut) => {
    if(!rut) return rut
    const dato = rut.toString().replace('.','')
    if(!dato) return rut
    const dato2 = dato.replace('.','')
    if(!dato2) return dato
    const dato3 = dato2.replace('.','')
    if(!dato3) return dato2
    return dato3
}

control.checkForDuplicatesEmails = (email,lista) => {
    const buscar = lista.filter(user => user.email === email)
    if(buscar.length > 0) return true
    return false
}

control.validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

control.sliceIntoChunks = (arr, chunkSize) => {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}

control.corregirTelefono = (num) => {

    if(!num){
      return "(en blanco)"
    }

    const digito = num.toString()

    const longitud = digito.length

    if(longitud === 0){
      return "(en blanco)"
    }
    const numero = digito.replace('+56', '56')
    if(longitud === 9){
      return `56${numero}`
    } else if(longitud === 8){
      return `569${numero}`
    }

    return numero
}

const timeStringToFloat = (time) => {
    if(!time) return false
    var hoursMinutes = time.split(/[.:]/);
    var hours = parseInt(hoursMinutes[0], 10);
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    return hours + minutes / 60;
}

control.validarHorario = (establecimiento) => {
  if(!establecimiento) return false
  if(establecimiento.cerrado === true) return false
  var fecha = new Date();
  fecha.toLocaleString('es-ES', { timeZone: 'America/Santiago' })
  const fecha_actual = new Date(fecha)
  const dia = fecha_actual.getDay()
  const hora = timeStringToFloat(`${fecha_actual.getHours()}:${fecha_actual.getMinutes()}`)

  if(dia === 1){
      const desde_1 = isNaN(timeStringToFloat(establecimiento.lunes_desde)) === true ? false : timeStringToFloat(establecimiento.lunes_desde)
      const hasta_1 = isNaN(timeStringToFloat(establecimiento.lunes_hasta)) === true ? false : timeStringToFloat(establecimiento.lunes_hasta)
      if(!desde_1 || !hasta_1){
          return false
      }
      if(hora >= desde_1 && hora < hasta_1 ) return true
  } else if(dia === 2){
      const desde_2 = isNaN(timeStringToFloat(establecimiento.martes_desde)) === true ? false : timeStringToFloat(establecimiento.martes_desde)
      const hasta_2 = isNaN(timeStringToFloat(establecimiento.martes_hasta)) === true ? false : timeStringToFloat(establecimiento.martes_hasta)
      if(!desde_2 || !hasta_2){
          return false
      }
      if(hora >= desde_2 && hora < hasta_2 ) return true
  } else if(dia === 3){
      const desde_3 = isNaN(timeStringToFloat(establecimiento.miercoles_desde)) === true ? false : timeStringToFloat(establecimiento.miercoles_desde)
      const hasta_3 = isNaN(timeStringToFloat(establecimiento.miercoles_hasta)) === true ? false : timeStringToFloat(establecimiento.miercoles_hasta)
      if(!desde_3 || !hasta_3){
          return false
      }
      if(hora >= desde_3 && hora < hasta_3 ) return true
  } else if(dia === 4){
      const desde_4 = isNaN(timeStringToFloat(establecimiento.jueves_desde)) === true ? false : timeStringToFloat(establecimiento.jueves_desde)
      const hasta_4 = isNaN(timeStringToFloat(establecimiento.jueves_hasta)) === true ? false : timeStringToFloat(establecimiento.jueves_hasta)
      if(!desde_4 || !hasta_4){
          return false
      }
      if(hora >= desde_4 && hora < hasta_4 ) return true
  } else if(dia === 5){
      const desde_5 = isNaN(timeStringToFloat(establecimiento.viernes_desde)) === true ? false : timeStringToFloat(establecimiento.viernes_desde)
      const hasta_5 = isNaN(timeStringToFloat(establecimiento.viernes_hasta)) === true ? false : timeStringToFloat(establecimiento.viernes_hasta)
      if(!desde_5 || !hasta_5){
          return false
      }
      if(hora >= desde_5 && hora < hasta_5 ) return true
  } else if(dia === 6){
      const desde_6 = isNaN(timeStringToFloat(establecimiento.sabado_desde)) === true ? false : timeStringToFloat(establecimiento.sabado_desde)
      const hasta_6 = isNaN(timeStringToFloat(establecimiento.sabado_hasta)) === true ? false : timeStringToFloat(establecimiento.sabado_hasta)
      if(!desde_6 || !hasta_6){
          return false
      }
      if(hora >= desde_6 && hora < hasta_6 ) return true
  } else if(dia === 7){
      const desde_7 = isNaN(timeStringToFloat(establecimiento.domingo_desde)) === true ? false : timeStringToFloat(establecimiento.domingo_desde)
      const hasta_7 = isNaN(timeStringToFloat(establecimiento.domingo_hasta)) === true ? false : timeStringToFloat(establecimiento.domingo_hasta)
      if(!desde_7 || !hasta_7){
          return false
      }
      if(hora >= desde_7 && hora < hasta_7 ) return true
  } 
  return false
}

module.exports = control