import React, { Component} from 'react'
import data, { async_storage_key, urlapi } from '../../lib/backend/data'
import { toast } from 'react-toastify';
import Carousel from 'react-bootstrap/Carousel'
import { Spinner, Container, Row, Col, Button, Card } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Modal from 'react-bootstrap/Modal'
import SearchLocationInput from '../../lib/maps/searchLocationInput'
import ScrollTabs from "../../lib/helpers/scrollTab";
import { ordenarProductos, ordenarRegistros, validarHorario } from '../../lib/helpers/helpers'
import Badge from 'react-bootstrap/Badge'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import { Link } from 'react-router-dom';
import { io } from 'socket.io-client';
import Header from '../Header';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
const maps_key = 'AIzaSyA2UVu71Lhw-lWjuTloT-VGIylp_63lgRY'

const cantidad_caracteres = 70

class Carta extends Component {
    constructor(props){
        super(props)
        this.state = {
            id_establecimiento: this.props.match.params.id,
            user: {
                id_establecimiento:"632b132fd64703093a2f9250",
                _id:"632b9c240262b451bffd2e7e",
                id_cuenta:"63233277ebed0fd6941c7ccc"
            },
            loadingMaster: true,
            establecimiento: false,
            modal_ubicacion: false,
            ubicaciones_usuario: [],
            carta_seleccionada: false,
            modal_producto: false,
            producto_seleccionado: false,
            carrito: localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [],
            show_drawer: false,
            modal_cerrado: false,
            abierto: false,
            color: '#393939',
            sucursales: [],
            mesa_seleccionada: false,
            enviandoComanda: false,
            escaneando: false,
            mostrarCodigoBarra: false,
            showModalEscaneo: false,
            sku_buscar: '',
            tipo_busqueda_codigo: "barcode",
            loadingAccionesProducto: false
        }

        this.handleChangeConfig = this.handleChangeConfig.bind(this)
        this.handleChangeDireccion = this.handleChangeDireccion.bind(this)
        this.handleChangeMesa = this.handleChangeMesa.bind(this)
        this.handleChangeState = this.handleChangeState.bind(this)
        this.buscarPorSku = this.buscarPorSku.bind(this)
    }

    componentDidMount(){
        const { user, id_establecimiento } = this.state
        this.obtenerInformacion(user.id_cuenta)
    }

    async obtenerInformacion(id){
        const { color } = this.state
        return fetch(`${data.urlapi}/api/establecimientos/sucursales?id=${id}`)
        .then(res => res.json())
        .then(async res => {
            if(!res){
                toast.error('No se pudo obtener el registro')
                return this.setState({ loadingMaster: false })
            } else if(Array.isArray(res) !== false){
                if(res.length === 1){
                    return await this.obtenerDetallesEstablecimiento(res[0]._id)
                } else if(res.length > 1){
                    this.setState({ sucursales: res })
                }
            }
            return this.setState({ loadingMaster: false, sucursales: res })
        })
        .catch(error => {
            this.setState({ loadingMaster: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    verificarMenuDefault(res) {
        return res.menus.length === 1 ? res.menus[0] : false
    }

    obtenerDetallesEstablecimiento(id){
        const { color } = this.state
        this.setState({ loadingMaster: true })
        return fetch(`${data.urlapi}/api/establecimientos/details-complete?id=${id}`)
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error('No se pudo obtener el registro')
                return this.setState({ loadingMaster: false })
            }
            const abierto = validarHorario(res.establecimiento)
            let carta_seleccionada = this.verificarMenuDefault(res)
            this.setState({ loadingMaster: false, establecimiento: res, modal_cerrado: abierto === true ? false : true, color: res.establecimiento.color ? res.establecimiento.color : color, carta_seleccionada, abierto })
        })
        .catch(error => {
            this.setState({ loadingMaster: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    siEsProductoCompuestoDinamico() {
        const { producto_seleccionado } = this.state
        if(producto_seleccionado.tipo !== "compuesto-dinamico") return false
        if(!producto_seleccionado.categorias) return false
        if(Array.isArray(producto_seleccionado.categorias) !== true) return false
        if(producto_seleccionado.categorias.length < 1) return false
        return <div>
            <h6>Este es un producto dinámico, selecciona entre las siguientes opciones</h6>
            {
                producto_seleccionado.categorias.map((cat,icat) => {

                    return <div key={`cat-${icat}`}>
                        <label className='form-control-label'>{cat.titulo}</label>
                        <select className='form-control' icat={icat} onChange={this.handleChangeOpcionDinamica}>
                            <option value="">Selecciona</option>
                            {
                                cat.opciones.map((op,io) => {
                                    return <option key={`${icat}-${io}`} value={io}>{op.titulo}</option>
                                })
                            }
                        </select>
                    </div>
                })
            }
        </div>
    }

    handleChangeDireccion(e){
        const { name, value } = e.target
        const pos = e.target.getAttribute('pos')
        const { ubicaciones_usuario } = this.state
        ubicaciones_usuario[pos][name] = value
        localStorage.setItem('my_places', JSON.stringify(ubicaciones_usuario))
        return this.setState({ ubicaciones_usuario })
    }

    handleChangeMesa(e) {
        const { value } = e.target
        return this.setState({ mesa_seleccionada: value })
    }

    handleChangeConfig(e){
        const { value } = e.target
        const pos = e.target.getAttribute('pos')
        const { producto_seleccionado } = this.state

        producto_seleccionado.configuracion[pos].opciones.map((opcion,i) => {
            return producto_seleccionado.configuracion[pos].opciones[i].seleccionado = false
        })

        if(value){
            const posicion = producto_seleccionado.configuracion[pos].opciones.findIndex(op => op.titulo === value)
            producto_seleccionado.configuracion[pos].opciones[posicion].seleccionado = true
        }

        return this.setState({ producto_seleccionado })
    }

    mostrarCarrusel(establecimiento){
        if(establecimiento.carrusel.length < 1) return false
        const items = ordenarRegistros(establecimiento.carrusel)
        return <Carousel>
            {
                items.map((banner,i) => {
                    return <Carousel.Item key={`banner-${i}`}>
                    <img
                      className="d-block w-100"
                      src={banner.url}
                      alt="Slide"
                    />
                  </Carousel.Item>
                })
            }
      </Carousel>
    }

    cargandoScreen(){
        return <Container>  
        <Row className="justify-content-md-center">
            <Col md={4} className="centerv text-center">
            <Spinner animation="grow" />
            </Col>
        </Row>        
    </Container>
    }

    handleClose(){
        return this.setState({ modal_ubicacion: false, modal_producto: false, producto_seleccionado: false, modal_cerrado: false, showModalEscaneo: false })
    }

    handleChangeState(e) {
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    buscarPorSku(e){
        e.preventDefault()
        const { sku_buscar } = this.state
        if(!sku_buscar) return false
        return this.consultarProductoPorCodigo(sku_buscar, 'sku')
    }

    showByTipoEscaneo(){
        const { tipo_busqueda_codigo } = this.state
        if(tipo_busqueda_codigo === "camera") return this.mostrarEscaneo()
    }

    modalEscaneo(){
        const { showModalEscaneo, escaneando, tipo_busqueda_codigo, sku_buscar } = this.state
        return <Modal show={showModalEscaneo} onHide={()=>this.handleClose()} centered closeButton >
        <Modal.Header closeButton>
          <Modal.Title>Escanea el código de barras</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={this.buscarPorSku}>
                { escaneando ? <Spinner animation='border' /> : <input id="campoescaneo" className='form-control' placeholder='BUSCAR VALOR' value={sku_buscar} name="sku_buscar" onChange={this.handleChangeState} /> }
            </form>
            <Row className='mt-3'>
                <Col md={4}>
                    <Card className='p-3 text-center hover' style={{ borderWidth: 2 }} border={tipo_busqueda_codigo === "barcode" ? "success" : ""} onClick={() => {
                        this.setState({ tipo_busqueda_codigo: "barcode" })
                        const input = document.getElementById("campoescaneo")
                        if(input) input.focus()
                        }} >
                        <h6>Buscar por código de barras</h6>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className='p-3 text-center hover' style={{ borderWidth: 2 }} border={tipo_busqueda_codigo === "sku" ? "success" : ""} onClick={() => {
                        this.setState({ tipo_busqueda_codigo: "sku" })
                        const input = document.getElementById("campoescaneo")
                        if(input) input.focus()
                        }} >
                        <h6>Buscar por código de SKU</h6>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className='p-3 text-center hover' style={{ borderWidth: 2 }} border={tipo_busqueda_codigo === "camera" ? "success" : ""} onClick={() => {
                        this.setState({ tipo_busqueda_codigo: "camera" })
                        const input = document.getElementById("campoescaneo")
                        if(input) input.focus()
                        }} >
                        <h6>Lector código de barra</h6>
                    </Card>
                </Col>
            </Row>
            {this.showByTipoEscaneo()}
        </Modal.Body>
      </Modal>
    }

    modalCerrado(){
        const { modal_cerrado } = this.state
        return <Modal show={modal_cerrado} onHide={()=>this.handleClose()} centered >
        <Modal.Header closeButton>
          <Modal.Title>¡Cerrado!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p className='mb-0'>En este momento estamos cerrados</p>
        </Modal.Body>
      </Modal>
    }

    detalleModal(){
        const { modal_ubicacion, ubicaciones_usuario } = this.state
        return <Modal show={modal_ubicacion} onHide={()=>this.handleClose()} centered >
        <Modal.Header closeButton>
          <Modal.Title>Mis direcciones</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className='text-center mb-3'><img src="images/home.png" alt="inicio" width={140} /></div>
        <p className='mb-0'>Ingresa tu ubicación para el despacho de tu compra</p>
        <SearchLocationInput
                maps_key={maps_key}
                onChange={(place) => {
                    if(!place) return false
                    const lat = place.geometry.location.lat()
                    const lng = place.geometry.location.lng()

                    let region = ''
                    const buscar_region = place.address_components.findIndex(comp => {
                        return comp.types.includes('administrative_area_level_1')
                    })
                    if(buscar_region > -1) region = place.address_components[buscar_region].long_name

                    let comuna = ''
                    const buscar_comuna = place.address_components.findIndex(comp => {
                        return comp.types.includes('administrative_area_level_2')
                    })
                    if(buscar_comuna > -1) comuna = place.address_components[buscar_comuna].long_name

                    const lugar = {
                        lat, 
                        lng,
                        direccion: place.formatted_address,
                        region,
                        comuna,
                        status: true
                    }
                    return this.setLugar_seleccionado(lugar)
                }} />
                <h5 className='mt-3'>Mis direcciones</h5>
                {
                    ubicaciones_usuario.map((ub,i) => {
                        return <Card className='mb-3'>
                            <div className='p-2'>
                            <p className='mb-0 hover' onClick={()=>this.seleccionarDireccion(i)}> <b>{ ub.status === true ? <i className="fas fa-check-square text-success"></i> : false } {ub.direccion}</b></p>
                            <input className='form-control' defaultValue={ub.observaciones} pos={i} placeholder='Casa · Depto · Observaciones de la dirección' name="observaciones" onChange={this.handleChangeDireccion} />
                            </div>
                        </Card>
                    })
                }
        </Modal.Body>
      </Modal>
    }

    agregarCarrito(){
        const { carrito, establecimiento, producto_seleccionado } = this.state
        const abierto = validarHorario(establecimiento.establecimiento)
        const cantidad = !isNaN(parseInt(producto_seleccionado.cantidad)) ? producto_seleccionado.cantidad : 1
        if(!cantidad) return toast.error("No hay cantidad disponible")
        let nuevo_producto = JSON.parse(JSON.stringify(producto_seleccionado))
        nuevo_producto.cantidad = cantidad
        nuevo_producto.precio_final = this.calcularPrecioFinal(nuevo_producto)
        carrito.push(nuevo_producto)
        console.log({carrito})
        setTimeout(() => {
            localStorage.setItem('cart', JSON.stringify(carrito))
        }, 1000);
        toast.success('Agregado exitosamente')
        console.log(nuevo_producto)
        
        /*
        productos.map((producto,ipro) => {
            if(!productos[ipro].configuracion) return false
            if(Array.isArray(productos[ipro].configuracion) !== true) return false
            if(productos[ipro].configuracion.length < 1) return false
            productos[ipro].configuracion.map((prr,iprr) => {
                if(!prr.opciones) return false
                if(Array.isArray(prr.opciones) !== true) return false
                if(prr.opciones.length < 1) return false
                prr.opciones.map((opcion,iop) => {
                    return productos[ipro].configuracion[iprr].opciones[iop].seleccionado = false
                })
            })
        })
        */

        return this.setState({ modal_producto: false, producto_seleccionado: false, modal_cerrado: abierto === true ? false : true })
    }

    seleccionarAcicional(i,iopcion){
        const { producto_seleccionado } = this.state
        producto_seleccionado.adicionales[i].opciones[iopcion].seleccionado = producto_seleccionado.adicionales[i].opciones[iopcion].seleccionado === true ? false : true
        return this.setState({ producto_seleccionado })
    }

    calcularPrecioFinal(producto){
        let precio_final = producto.precio ? producto.precio : 0
        let cantidad = producto.cantidad ? producto.cantidad : 1
        precio_final = precio_final * cantidad
        // VER OPCIONES
        if(producto.opciones){
            if(Array.isArray(producto.opciones)){
                if(producto.opciones.length > 0){
                    precio_final = 0
                    const encontrar_seleccion = producto.opciones.findIndex(op => op.seleccionado === true)
                    if(encontrar_seleccion > -1){
                        const precio_opcion_seleccionada = producto.opciones[encontrar_seleccion].precio
                        if(isNaN(precio_opcion_seleccionada) !== true){
                            precio_final = precio_opcion_seleccionada * cantidad
                        }
                    }
                }
            }
        }

        // VER ADICIONALES
        if(producto.adicionales){
            if(Array.isArray(producto.adicionales)){
                if(producto.adicionales.length > 0){
                    const encontrar_adicionales_seleccionados = producto.adicionales.filter(op => {
                        if(!op.opciones) return false
                        if(Array.isArray(op.opciones) !== true) return false
                        if(op.opciones.length < 1) return false
                        return op.opciones.filter(ad => ad.seleccionado === true).length > 0
                    })
                    for(const adicional of encontrar_adicionales_seleccionados){
                        for( const opcion of adicional.opciones ){
                            if(opcion.seleccionado !== true) continue
                            if(isNaN(opcion.precio) !== true){
                                precio_final = precio_final + opcion.precio      
                            }
                        }
                    }
                }
            }
        }

        return precio_final
    }

    mostrarConfiguracion(configuracion){
        if(!configuracion) return false
        if(Array.isArray(configuracion) !== true) return false
        if(configuracion.length < 1) return false
        return <div>
            <hr />
            <h4>Selecciona las opciones </h4>
            {
                configuracion.map((opcion,i) => {
                    return <div key={`maxsub-${i}`}>
                        <h5>{opcion.titulo} <span style={{ fontSize: 12 }} className='text-danger'>Requerido</span></h5>
                                <div >
                                    <Row>
                                        <Col xs={12} className="hover">
                                             <select className='form-control' pos={i} onChange={this.handleChangeConfig} >
                                                 <option value="">Selecciona</option>
                                                 {
                                                     opcion.opciones.map((subopcion,isub) => {
                                                         return <option value={subopcion.titulo}>{subopcion.titulo}</option>
                                                     })
                                                 }
                                             </select>
                                        </Col>
                                    </Row>
                                </div>
                        {configuracion.length - 1 === i ? false : <hr />}
                    </div>
                })
            }
        </div>
    }

    mostrarAdicionales(adicionales){
        if(!adicionales) return false
        if(Array.isArray(adicionales) !== true) return false
        if(adicionales.length < 1) return false
        return <div>
            <h4>Adicionales</h4>
            {
                adicionales.map((opcion,i) => {
                    return <div key={`maxsub-${i}`}>
                        <h5>{opcion.titulo} <span style={{ fontSize: 12 }} className='text-warning'>Opcional</span></h5>
                        {
                            opcion.opciones.map((subopcion,isub) => {
                                return <div key={`opcion-${isub}`}>
                                    <Row>
                                        <Col xs={6} className="hover" >{ subopcion.seleccionado === true ? <i className="text-success fas fa-check-square"></i> : <i className="far fa-square"></i> } {subopcion.titulo}</Col>
                                        <Col xs={6} className='text-right'> {parseInt(subopcion.precio) > 0 ? `$${Intl.NumberFormat("es-ES").format(subopcion.precio)}` : false } </Col>
                                    </Row>
                                </div>
                            })
                        }
                        {adicionales.length - 1 === i ? false : <hr />}
                    </div>
                })
            }
        </div>
    }

    seleccionarOpcion(i){
        const { producto_seleccionado } = this.state
        producto_seleccionado.opciones.map((op,iop) => producto_seleccionado.opciones[iop].seleccionado = false)
        producto_seleccionado.opciones[i].seleccionado = true
        return this.setState({ producto_seleccionado })
    }

    mostrarOpciones(opciones){
        if(opciones.length < 1) return false
        return <div>
            <h4>Opciones <span style={{ fontSize: 12 }} className='text-danger'>Requerido</span></h4>
            {
                opciones.map((opcion,i) => {
                    return <div key={`opcion-${i}`}>
                        <Row>
                            <Col xs={6} className="hover" >{ opcion.seleccionado === true ? <i className="text-success fas fa-check-square"></i> : <i className="far fa-square"></i> } {opcion.titulo}</Col>
                            <Col xs={6} className='text-right'> {parseInt(opcion.precio) > 0 ? `$${Intl.NumberFormat("es-ES").format(opcion.precio)}` : false } </Col>
                        </Row>
                    </div>
                })
            }
        </div>
    }

    siEsProductoCompuesto(producto){
        if(producto.tipo === "compuesto"){
            if(!producto.agrupados) return false
            if(Array.isArray(producto.agrupados) !== true) return false
            if(producto.agrupados.length < 1) return false
            return <div className='mb-3'>
                <h5 style={{ 
                    background: "#0087ff", 
                    display: "inline-block", 
                    padding: "5px 15px", 
                    fontSize: 15, 
                    color: "white", 
                    borderRadius: 6 
                }}>PRODUCTO COMPUESTO</h5>
                <Row>
                {
                    producto.agrupados.map((pro,i) => {
                        return <Col key={`var-${i}`}>
                            <Card className='p-3'>
                            <h6 className='mb-0'>{pro.titulo}</h6>
                            <h6 className='mb-0' style={{ color: "#c2c2c2" }}>{pro.SKU}</h6>
                            <h6>${Intl.NumberFormat("es-ES").format(pro.precio)}</h6>
                            </Card>
                        </Col>
                    })
                }
            </Row>
            </div>
        }
    }

    mostrarInventarioSiAplica(producto){
        if(producto.manage_stock !== true) return false
        return <h6 style={{ background: "#777", display: "inline-block", padding: "2px 6px", borderRadius: 4, color: "white" }}>Inventario: {producto.stock}</h6>
    }

    detalleModalProducto(){
        const { modal_producto, producto_seleccionado } = this.state
        if(!producto_seleccionado) return false

        const opciones = producto_seleccionado.opciones ? producto_seleccionado.opciones : []
        const adicionales = producto_seleccionado.adicionales ? producto_seleccionado.adicionales : []
        const configuracion = producto_seleccionado.configuracion ? producto_seleccionado.configuracion : []
        let cantidad_agregar = !isNaN(parseInt(producto_seleccionado.cantidad)) ? producto_seleccionado.cantidad : 1
        if(producto_seleccionado.manage_stock === true){
            if(producto_seleccionado.stock < 1) cantidad_agregar = 0
        }

        return <Modal show={modal_producto} onHide={()=>this.handleClose()} centered size="xl" >
            <Modal.Header closeButton>
        <Modal.Title><h2 className='mb-0'>{producto_seleccionado.titulo}</h2></Modal.Title>
      </Modal.Header>
        <Row>
                <Col md={6}>
                <img style={{ maxHeight: '80vh', maxWidth: '100%', minWidth: '100%' }} alt="producto" src={producto_seleccionado.imagen} />
                </Col>

                <Col md={6}>
                    <div className='pt-3 box-producto' style={{ paddingBottom: 20 }}>
                        <div className='bloque-opciones-producto-v2'>
                        <p onClick={()=>this.handleClose()} className="mb-3 hover"><b><i className="fas fa-arrow-left"></i> Atrás</b></p>
                        <Row>
                            <Col xs={4}><p className='skuspan'><b>SKU</b> {producto_seleccionado.sku}</p></Col>
                            <Col xs={4}><p className='skuspan'><b>CAL</b> {producto_seleccionado.calorias}</p></Col>
                            <Col xs={4}><p className='skuspan'><b>CARB</b> {producto_seleccionado.carbohidratos}</p></Col>
                        </Row>
                        <p>{producto_seleccionado.descripcion}</p>
                        {this.siEsProductoCompuesto(producto_seleccionado)}
                        {this.siEsProductoCompuestoDinamico()}
                        <div >
                            {this.mostrarOpciones(opciones)}
                            { opciones.length > 0 && adicionales.length > 0 ? <hr /> : false }
                            {this.mostrarAdicionales(adicionales)}
                            {this.mostrarConfiguracion(configuracion)}
                        </div>
                            
                        </div>
                    </div>
                </Col>
            </Row>
      </Modal>
    }

    cantidadEnCarrito(id){
        const { carrito } = this.state
        const en_carrito = carrito.filter(p => p._id === id)
        let cantidad_en_carrito = 0
        for( const product of en_carrito){
            cantidad_en_carrito = cantidad_en_carrito + product.cantidad
        }
        return cantidad_en_carrito
    }

    sumaRestaProducto(operacion){
        const { producto_seleccionado } = this.state
        const cantidad_actual = producto_seleccionado.cantidad ? producto_seleccionado.cantidad : 1
        if(operacion === 'suma'){
            let nueva_cantidad = cantidad_actual + 1
            // VERIFICAR STOCK
            if(producto_seleccionado.manage_stock === true){
                let inventario = producto_seleccionado.stock
                const cantidad_carrito = this.cantidadEnCarrito(producto_seleccionado._id)
                nueva_cantidad = nueva_cantidad + cantidad_carrito
                console.log({nueva_cantidad})
                if(nueva_cantidad > inventario) return false
            }
            producto_seleccionado.cantidad = nueva_cantidad
        } else {
            if(cantidad_actual > 1){
                const nueva_cantidad = cantidad_actual - 1
                producto_seleccionado.cantidad = nueva_cantidad
            }
        }
        return this.setState({ producto_seleccionado })
    }

    permitirAgregarCarrito(producto){
        const { loadingAccionesProducto } = this.state
        let permitir = true
        if(producto.opciones){
            if(Array.isArray(producto.opciones)){
                if(producto.opciones.length > 0){
                    const encontrar_seleccion = producto.opciones.findIndex(op => op.seleccionado === true)
                    if(encontrar_seleccion < 0) permitir = false
                }
            }
        }
        if(producto.configuracion){
            if(Array.isArray(producto.configuracion)){
                if(producto.configuracion.length > 0){
                    const opciones_vacias = producto.configuracion.filter(conf => {
                        return conf.opciones.filter(op => op.seleccionado === true).length < 1 ? true : false
                    })
                    if(opciones_vacias.length > 0) permitir = false
                }
            }
        }

        if(!permitir) return <Button variant='secondary' style={{ width: '100%' }} >ELIGE UNA OPCIÓN</Button>

        return <div>
            { loadingAccionesProducto ? <Spinner animation="grow" /> : <Button variant='primary' style={{ width: '100%' }} onClick={()=>this.agregarCarrito(producto)} >AGREGAR ${Intl.NumberFormat("es-ES").format(this.calcularPrecioFinal(producto))}</Button> }
        </div>

    }

    seleccionarDireccion(pos){
        const { ubicaciones_usuario } = this.state
        ubicaciones_usuario.map((ub,i) => ubicaciones_usuario[i].status = false)
        ubicaciones_usuario[pos].status = true
        localStorage.setItem('my_places', JSON.stringify(ubicaciones_usuario))
        return this.setState({ ubicaciones_usuario })
    }

    setLugar_seleccionado(place){
        const { ubicaciones_usuario } = this.state
        const si_existe = ubicaciones_usuario.findIndex(ub => ub.direccion === place.direccion)
        ubicaciones_usuario.map((ub,i) => ubicaciones_usuario[i].status = false)
        if(si_existe > -1){
            ubicaciones_usuario[si_existe].status = true
            toast.warn('Esta dirección ya existe')
        } else {
            ubicaciones_usuario.push(place)
        }
        localStorage.setItem('my_places', JSON.stringify(ubicaciones_usuario))
        return this.setState({ ubicaciones_usuario })
    }

    ifInCarrito(id){
        const { carrito } = this.state
        const cuantos = carrito.filter(pro => pro._id === id).length
        if(cuantos > 0) return <Badge className='contador-carrito bg-danger text-light' >{cuantos}</Badge>
        return false
    }

    toggleDrawer(e){
        return this.setState({ show_drawer: false })
    }

    mostrarOpcionEscogida(producto){
        if(producto.opciones){
            if(Array.isArray(producto.opciones)){
                if(producto.opciones.length > 0){
                    const encontrar_seleccion = producto.opciones.findIndex(op => op.seleccionado === true)
                    if(encontrar_seleccion > -1){
                        return <p className='mb-0'>{producto.opciones[encontrar_seleccion].titulo}</p>
                    }
                }
            }
        }
        return false
    }

    visualizarConfiguracion(producto){
        let escogidos = []
        if(producto.configuracion){
            if(Array.isArray(producto.configuracion)){
                if(producto.configuracion.length > 0){
                    const encontrar_config_seleccionado = producto.configuracion.filter(op => {
                        if(!op.opciones) return false
                        if(Array.isArray(op.opciones) !== true) return false
                        if(op.opciones.length < 1) return false
                        return op.opciones.filter(ad => ad.seleccionado === true).length > 0
                    })
                    for(const config of encontrar_config_seleccionado){
                        for( const opcion of config.opciones ){
                            if(opcion.seleccionado !== true) continue
                            escogidos.push(opcion.titulo)
                        }
                    }
                }
            }
        }
        return escogidos.length > 0 ? escogidos.map((item,i) => <span className='spanguia mr-2' key={`item-${i}`}>{item}</span>) : false

    }

    visualizarAdicionales(producto){
        let escogidos = []
        if(producto.adicionales){
            if(Array.isArray(producto.adicionales)){
                if(producto.adicionales.length > 0){
                    const encontrar_adicionales_seleccionados = producto.adicionales.filter(op => {
                        if(!op.opciones) return false
                        if(Array.isArray(op.opciones) !== true) return false
                        if(op.opciones.length < 1) return false
                        return op.opciones.filter(ad => ad.seleccionado === true).length > 0
                    })
                    for(const adicional of encontrar_adicionales_seleccionados){
                        for( const opcion of adicional.opciones ){
                            if(opcion.seleccionado !== true) continue
                            escogidos.push(opcion.titulo)
                        }
                    }
                }
            }
        }
        return escogidos.length > 0 ? escogidos.map((item,i) => <span className='spanguia mr-2' key={`item-${i}`}>{item}</span>) : false
    }

    removerDeCarrito(i){
        const { carrito } = this.state
        carrito.splice(i,1)
        localStorage.setItem('cart', JSON.stringify(carrito))
        return this.setState({ carrito   })
    }

    enviarComanda(){
        const { mesa_seleccionada, user, carrito, establecimiento } = this.state
        if(!mesa_seleccionada) return toast.error("Debes seleccionar primero una mesa")
        const i = establecimiento.mesas.findIndex(m => m._id === mesa_seleccionada)
        const payload  = {
            productos: carrito,
            user,
            id_establecimiento: establecimiento.establecimiento._id,
            mesa_seleccionada: establecimiento.mesas[i]
        }
        const loading = "enviandoComanda"
        this.setState({ [loading]: true })
        return fetch(`${urlapi}/api/comandas`,{
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error('No se obtuvieron datos del servidor')
                return this.setState({ [loading]: false })
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ [loading]: false })
            } else if(res._id){
                localStorage.setItem('cart', JSON.stringify([]))
                this.setState({ carrito: [], mesa_seleccionada: "" })
                toast.success("Comanda enviada exitosamente")
            }
            return this.setState({ [loading]: false })
        })
        .catch(error => {
            toast.error(error.message)
            return this.setState({ [loading]: false })
        })
    }

    async consultarStock(id){
        const loading = "loadingAccionesProducto"
        this.setState({ [loading]: true })
        return fetch(`${urlapi}/api/productos/stock?id=${id}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('No se obtuvieron datos del servidor')
                this.setState({ [loading]: false })
                return false
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                this.setState({ [loading]: false })
                return false
            } else if(res.stock){
                this.setState({ [loading]: false })
                return res.stock
            }
            this.setState({ [loading]: false })
            return false
        })
        .catch(error => {
            toast.error(error.message)
            this.setState({ [loading]: false })
            return false
        })
    }

    consultarProductoPorCodigo(codigo) {
        const { user, tipo_busqueda_codigo } = this.state
        const loading = "escaneando"
        this.setState({ [loading]: true, mostrarCodigoBarra: false  })
        return fetch(`${urlapi}/api/appgarzon/scan-barcode`,{
            method: "POST",
            body: JSON.stringify({
                codigo,
                tipo: tipo_busqueda_codigo
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${user._id}`
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error('No se obtuvieron datos del servidor')
                this.setState({ [loading]: false, sku_buscar: "", })
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                this.setState({ [loading]: false, sku_buscar: "", })
            } else if(res.titulo){
                this.setState({ mostrarCodigoBarra: false, sku_buscar: "", producto_seleccionado: res, modal_producto: true, showModalEscaneo: false })
            }
            this.setState({ [loading]: false })
            const input = document.getElementById("campoescaneo")
            if(input) input.focus()
            return true
        })
        .catch(error => {
            toast.error(error.message)
            this.setState({ [loading]: false, sku_buscar: "", mostrarCodigoBarra: false })
            const input = document.getElementById("campoescaneo")
            if(input) input.focus()
            return false
        })
    }

    mostrarProductos(){
        const { establecimiento, enviandoComanda, carrito, mesa_seleccionada } = this.state
        if(carrito.length < 1) return <h3>Aún no tienes productos agregados</h3>

        let calcular_total = carrito.reduce((prev, next) => {
            return prev + this.calcularPrecioFinal(next)
        }, 0)

        let mesas = []
        if(establecimiento.mesas){
            if(Array.isArray(establecimiento.mesas) !== false){
                mesas = [...[], ...establecimiento.mesas]
            }
        }

        return <div>
            <div className='box-producto-carrito'>
            <h5><i className="fas fa-arrow-left hover" onClick={()=>this.toggleDrawer()} ></i> Detalles de tu pedido</h5>
            <h5 className='text-danger mb-3'>{carrito.length} Productos</h5>
            {
                carrito.map((pro,i) => {
                    return <Row key={`pro-${i}`}>
                        <Col xs={8}>
                            <h5 className='mb-0'>{pro.titulo} <span className='text-danger'>X{pro.cantidad}</span> {this.visualizarConfiguracion(pro)}</h5>
                            {this.mostrarOpcionEscogida(pro)}
                            <h6 className='mb-0'>${Intl.NumberFormat("es-ES").format(pro.precio_final)}</h6>
                            {this.visualizarAdicionales(pro)}
                            <Button size="sm" variant="outline-danger" className="mt-2" style={{ fontSize:10 }} onClick={()=>this.removerDeCarrito(i)}>REMOVER</Button>
                        </Col>
                        <Col xs={4}>
                        <img alt="producto-imagen" src={pro.imagen} style={{ width: '100%' }} />
                        </Col>
                        {carrito.length - 1 === i ? false : <Col xs={12} ><hr /></Col> }
                    </Row>
                })
            }
            </div>
            <Row className='pr-4 pl-3 botonera-modal-producto'>
                            <Col xs={12}>
                            <select name="mesa" className="form-control mb-3" value={mesa_seleccionada} onChange={this.handleChangeMesa}>
                                <option value="">Selecciona una mesa</option>
                                {
                                    mesas.map(mesa => {
                                        return <option value={mesa._id}>MESA {mesa.id}</option>
                                    })
                                }
                            </select>
                            {
                                enviandoComanda ? <h4 className='mb-0'>Enviando comanda...</h4> : <Button style={{ width: '100%' }} onClick={() => this.enviarComanda()} >ENVIAR COMANDA ${Intl.NumberFormat("es-ES").format(calcular_total)}</Button>
                            }
                            </Col>
                        </Row>
        </div>
    }

    mostrarCarrito(){
        const { show_drawer } = this.state

        return <Drawer
        open={show_drawer}
        onClose={()=>this.toggleDrawer()}
        direction='right'
        size={400}
        className='drawer-carrito'
    >
        <div className='p-3' >
            {this.mostrarProductos()}
        </div>
    </Drawer>
    }

    async seleccionarProducto(pro){
        const { carrito } = this.state

        pro.cantidad = 1

        if(pro.configuracion){
            if(Array.isArray(pro.configuracion) !== false ){
                if(pro.configuracion.length > 0){
                    pro.configuracion.map((conf,pos) => {
                        pro.configuracion[pos].opciones.map((opcion,i) => {
                            return pro.configuracion[pos].opciones[i].seleccionado = false
                        })
                        return true
                    })
                }
            }
        }

        if(pro.opciones){
            if(Array.isArray(pro.opciones)){
                if(pro.opciones.length > 0){
                    pro.opciones.map((opcion,pos) => {
                        return pro.opciones[pos].seleccionado = false
                    })
                }
            }
        }

        if(pro.manage_stock === true){
            const cantidad = await this.consultarStock(pro._id)
            pro.stock = cantidad
            const en_carrito = carrito.filter(p => p._id === pro._id)
            let cantidad_en_carrito = 0
            for( const product of en_carrito){
                cantidad_en_carrito = cantidad_en_carrito + product.cantidad
            }
            if(cantidad_en_carrito >= cantidad) pro.cantidad = 0
        } else {
            // REFRESCAR PRODUCTO POR SI CAMBIARON RECIEN EL MANEJO DE INVENTARIO
        }
        

        return this.setState({  producto_seleccionado: pro, modal_producto: true })
    }

    smallString(pro) {
        if(!pro.descripcion) return false
        return pro.descripcion.length > cantidad_caracteres ? "..." : false
    }

    boxesProducto(productos, titulo){
        
        return <Row>
            <Col xs={12}>
                <h3>{titulo}</h3>
                <hr className='hr' />
            </Col>
            {
                productos.map((pro,ip) => {
                    return <Col md={4} className='mb-3' >
                        <Card className="hover" onClick={()=>this.seleccionarProducto(pro)}>
                        
                        <Row>
                            <Col xs={7}>
                            <div className='p-3'>
                                <h6>{pro.titulo} </h6>
                                
                                <p style={{ fontSize: 12 }}>{pro.descripcion ? pro.descripcion.substring(0,cantidad_caracteres) : false } {this.smallString(pro, cantidad_caracteres)} </p>
                                <h6>${Intl.NumberFormat("es-ES").format(pro.precio)}</h6>
                            </div>
                            </Col>
                            <Col xs={5}>
                            {this.ifInCarrito(pro._id)}
                                <img alt="producto-imagen" src={pro.imagen} style={{ width: '100%' }} />
                            </Col>
                        </Row>
                        
                    </Card>
                    </Col>
                })
            }
        </Row>
    }

    mostrarHorarios(establecimiento){
        let horarios = []
        if(establecimiento.lunes_desde && establecimiento.lunes_hasta) horarios.push(`Lunes: ${establecimiento.lunes_desde} a ${establecimiento.lunes_hasta}`)
        if(establecimiento.martes_desde && establecimiento.martes_hasta) horarios.push(`Martes: ${establecimiento.martes_desde} a ${establecimiento.martes_hasta}`)
        if(establecimiento.miercoles_desde && establecimiento.miercoles_hasta) horarios.push(`Miércoles: ${establecimiento.miercoles_desde} a ${establecimiento.miercoles_hasta}`)
        if(establecimiento.jueves_desde && establecimiento.jueves_hasta) horarios.push(`Jueves: ${establecimiento.jueves_desde} a ${establecimiento.jueves_hasta}`)
        if(establecimiento.viernes_desde && establecimiento.viernes_hasta) horarios.push(`Viernes: ${establecimiento.viernes_desde} a ${establecimiento.viernes_hasta}`)
        if(establecimiento.sabado_desde && establecimiento.sabado_hasta) horarios.push(`Sábado: ${establecimiento.sabado_desde} a ${establecimiento.sabado_hasta}`)
        if(establecimiento.domingo_desde && establecimiento.domingo_hasta) horarios.push(`Domingo: ${establecimiento.domingo_desde} a ${establecimiento.domingo_hasta}`)
        return horarios.map((horario,i) => <p className='mb-0' key={`horario-${i}`}>{horario}</p>)
    }

    footer(){
        const { establecimiento, color } = this.state
        let local = {}
        if(typeof establecimiento.establecimiento === 'object'){
            local = establecimiento.establecimiento
        }
        return <footer className='mt-4' style={{ backgroundColor: color }}>
            <div className='p-3 text-white'>
            <Container>
            <Row>

{
    local.logotipo ? <Col md={3} className="mb-3">
        <img alt="logotipo" src={local.logotipo} style={{ width: '100%' }} />
    </Col> : false
}

<Col md={3} className="mb-3">
    <h3>Nosotros</h3>
    <h5>{local.titulo}</h5>
    <p>{local.direccion}</p>
</Col>


<Col md={3} className="mb-3">

{ local.instagram || local.facebook || local.twitter ? <h3>Síguenos</h3> : false }

{
    local.telefono ? <a className='mr-3' rel='noreferrer' href={`https://api.whatsapp.com/send?l=en&phone=${local.telefono}`} target="_blank">
        <i style={{ fontSize: 30, color: 'white' }} className="fab fa-whatsapp"></i>
        </a> : false
}
{
    local.instagram ? <a className='mr-3' rel='noreferrer' href={`https://instagram.com/${local.instagram}`} target="_blank">
        <i style={{ fontSize: 30, color: 'white' }} className="fab fa-instagram"></i>
        </a> : false
}

{
    local.facebook ? <a className='mr-3' rel='noreferrer' href={`${local.facebook}`} target="_blank">
        <i style={{ fontSize: 30, color: 'white' }} className="fab fa-facebook"></i>
        </a> : false
}

{
    local.twitter ? <a className='mr-3' rel='noreferrer' href={`${local.twitter}`} target="_blank">
        <i style={{ fontSize: 30, color: 'white' }} className="fab fa-twitter"></i>
        </a> : false
}

{ local.instagram || local.facebook || local.twitter ? <hr className='hr' /> : false }

<h3 className="">Medios de pago</h3>
{ establecimiento.tipos_pago ? establecimiento.tipos_pago.map((tipo,i) => <p key={`tipo-pago-${tipo._id}`}>{tipo.title}</p>) : false }

</Col>

<Col md={3} className="mb-3">
    <h3>Horarios</h3>
    {this.mostrarHorarios(local)}
</Col>

<Col md={12} className="text-center">
    <hr className='hr' />
    <p className='mt-3'>Todos los derechos reservados. {local.titulo}</p>
</Col>

</Row>
            </Container>
            </div>
        </footer>
    }

    mostrarCartas(){
        const { establecimiento } = this.state

        const Error = () => {
            return <h4>Error, recarga la página</h4>
        }

        if(!establecimiento) return <Error />

        let menus = []
        if(!establecimiento.menus) return <Error />
        if(Array.isArray(establecimiento.menus) !== true) return <Error />

        return <div className='p-5'>
        <Row className="justify-content-md-center">
            <Col md={6} className="text-center">
                <Row>
                    <Col xs={12}>
                        <h2 className='mb-4'>Selecciona una carta</h2>
                    </Col>
                    {
                        establecimiento.menus.map(carta => {
                            let nombre = ''
                            if(carta.menu){
                                if(typeof carta.menu === "object"){
                                    if (carta.menu.title) nombre = carta.menu.title
                                }
                            }
                            if(!carta.categorias) return false
                            if(Array.isArray(carta.categorias) !== true) return false
                            if(carta.categorias.length < 1) return false

                            return <Col  md key={carta._id} className="mb-3 hover" onClick={() => this.setState({ carta_seleccionada: carta })}>
                                <Card className='p-4'>
                                    <p className='mb-0'>Carta</p>
                                    <h1>{nombre}</h1>
                                </Card>
                                </Col>
                        })
                    }
            </Row>
            </Col>
        </Row>
    </div>
    }

    mostrarSucursales(sucursales){
        return <div className='p-5'>
        <Row className="justify-content-md-center">
            <Col md={6} className="text-center">
                <Row>
                    <Col xs={12}>
                        <h2 className='mb-4'>Selecciona una sucursal</h2>
                    </Col>
                    {
                        sucursales.map(sucursal => {
                            return <Col  md key={sucursal._id} className="mb-3 hover" onClick={() => this.obtenerDetallesEstablecimiento(sucursal._id)}>
                                <Card className='p-4'>
                                    <h4>{sucursal.titulo}</h4>
                                    <p className='mb-0'>{sucursal.direccion}</p>
                                </Card>
                                </Col>
                        })
                    }
            </Row>
            </Col>
        </Row>
    </div>
        
    }

    mostrarEscaneo() {
        const { mostrarCodigoBarra, escaneando } = this.state
        if(!mostrarCodigoBarra){
            if(escaneando){
                return <Spinner animation='border' />
            }
        }

        return <BarcodeScannerComponent
        width={"100%"}
        height={500}
        facingMode="environment"
        onUpdate={ async (err, result) => {
          if (result){
            this.consultarProductoPorCodigo(result.text)
          }
        }}
      />
    }

    mostrarInterfaz(){
        const { establecimiento, loadingMaster, color, showModalEscaneo, sucursales, carrito, ubicaciones_usuario, carta_seleccionada } = this.state
        if(loadingMaster) return this.cargandoScreen()
        if(!establecimiento){
            if(sucursales.length < 1) return false
            return this.mostrarSucursales(sucursales)
        }

        if(!carta_seleccionada) return this.mostrarCartas()

        let categorias = []

        if(establecimiento.productos_destacados){
            if(establecimiento.productos_destacados){
                if(Array.isArray(establecimiento.productos_destacados) !== false){
                    if(establecimiento.productos_destacados.length > 0){
                        const titulo_destacados = "Productos destacados"
                        categorias.push({
                            text: titulo_destacados,
                            component: this.boxesProducto(ordenarProductos(establecimiento.productos_destacados), titulo_destacados)
                        })
                    }
                }
            }
        }

        if(carta_seleccionada){
            if(carta_seleccionada.categorias){
                if(Array.isArray(carta_seleccionada.categorias) !== false){
                    if(carta_seleccionada.categorias.length > 0){
                        carta_seleccionada.categorias.map( cat => {
                            if(cat.productos.length < 1) return false
                            categorias.push({
                                text: cat.categoria.title,
                                component: this.boxesProducto(ordenarProductos(cat.productos), cat.categoria.title)
                            })
                            return true
                        })
                    }
                }
            }
        }
        

        let titulo = 'Ingresa tu ubicación'
        const buscar_direccion_seleccionada = ubicaciones_usuario.findIndex(ub => ub.status === true)

        if( buscar_direccion_seleccionada > -1 ){
            titulo = ubicaciones_usuario[buscar_direccion_seleccionada].direccion
        }
        

        return <div>
            {this.mostrarCarrusel(establecimiento)}
            <ScrollTabs
            tabsInScroll={categorias}
            />
            {this.footer()}
        </div>
    }

    render(){
        return <div>
            {this.modalEscaneo()}
            {this.modalCerrado()}
            {this.mostrarCarrito()}
            {this.detalleModal()}
            {this.detalleModalProducto()}
            {this.mostrarInterfaz()}
        </div>
    }

}

export default Carta