import { toast } from 'react-toastify';
import data from '../lib/backend/data'

export const userService = {
    login,
    logout
};

function login( rut, codigo ) {
    console.log({ codigo })
    return fetch(`${data.urlapi}/api/garzones/login`, {
        method: 'POST',
        body: JSON.stringify({
            rut: rut.toLowerCase(),
            codigo
        }),
        headers: {
        'Content-Type':'application/json'
        }
    })
        .then(user => user.json())
        .then(user => { 
            console.log(user)
            if(!user){
                toast.error("Error al iniciar sesión")
                return false
            } else if(user.errorMessage){
                toast.error(user.errorMessage)
                return false
            } else if(user._id) {
                user.authdata = window.btoa(rut + ':' + codigo);
                localStorage.setItem( data.async_storage_key , JSON.stringify(user));
                return user;
            }
            return false;
        });
}

function logout() {
    localStorage.removeItem( data.async_storage_key );
}
