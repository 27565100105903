import React from "react";
import { fechaATexto } from "../../lib/helpers/helpers";

export const ImprimirTicket = React.forwardRef((props, ref) => {
    const mesa = props.mesa
    const logotipo = props.logotipo ? props.logotipo : false
    if(!mesa) return false

    const construirHTML = (myBase64string) => {
      var objbuilder = '';
      objbuilder += ('<object width="100%" height="100%" data="data:application/pdf;base64,');
      objbuilder += (myBase64string);
      objbuilder += ('" type="application/pdf" class="internal">');
      objbuilder += ('<embed src="data:application/pdf;base64,');
      objbuilder += (myBase64string);
      objbuilder += ('" type="application/pdf"  />');
      objbuilder += ('</object>');
      return objbuilder
    }

    const mostrarPDF = (data) => {
      if(!data) return <h4>PDF no se puede visualizar</h4>
      const html = construirHTML(data)
      return <div style={{ height: "100%" }}>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    }

    return (
      <div ref={ref} className="p-3 text-center">
        {
          logotipo ? <img style={{ width: "40%" }} src={logotipo} /> : false
        }
        <h1 className="mb-0">TICKET {mesa.id}</h1>
        <p className="mb-0">Zona horaria: <b>America/Santiago</b></p>
        <p className="mb-0">{fechaATexto(mesa.fecha)}</p>
        <h5 className="mb-1">Emitido por {mesa.garzon ? mesa.garzon : "Usuario"}</h5>
        <p className="mb-3"><b style={{ fontWeight: 'bold' }}>tremus.cl</b></p>
        {mostrarPDF(mesa.pdf)}
      </div>
    );
});